<template>
  <ManageImages :folder="'flash'"></ManageImages>
</template>

<script>
import ManageImages from './ManageImages.vue'
export default {
    components: {
        ManageImages
    }
}
</script>

<style>

</style>