<template>
<div>
    <h1>Coming soon...</h1>
    <img class="bow-image" :src="require('../assets/imgs/bow.png')" alt=""> 
</div>
</template>

<script>
export default {

}
</script>

<style>
.bow-image {
    max-width: 300px;
}
</style>