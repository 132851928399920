<template>
  <div class="about-page">
    <h1>About</h1>
    <p>My name is Justyne (they/them) and I am a self-taught machine tattooer, illustrator, and mixed media artist based in Boston, MA.  I have been tattooing since August 2021 and have fallen more and more in love with it since! My work is not perfect as I am very much still learning a lot, and I try to be very transparent about that. I am still working on getting more solid, consistent, and steady lines, solid color, smooth greys, etc.  My goal is to create and hold a comfortable, inviting and inclusive space for clients to accept new art on their bodies; tattooing is a very intimate and possibly nerve-wracking experience, so having this space be pleasant, enjoyable and safe is the biggest priority for me! Besides giving you a piece of permanent art that you will hopefully adore, of course. </p>
    <p>While I have no formal degree in art, I have been making art my whole life and have taken formal illustration, animation, drawing and painting classes. I have an undergraduate degree in Biochemistry and Molecular Biology, but have left my research position in June 2022 to pursue my dream of tattooing full time. When I’m not tattooing, I love trying new art mediums, pretty much any outdoor activity (hiking, camping, biking, roller skating, canoeing, rock climbing, you name it), decorating my home, and taking care of our two kittens and four chickens with my love (also desperately want a puppy). </p>
    <p>My art and tattoos are often inspired by fantasy games and literature, magical beings, kitschy objects, botanicals, and cute animals or characters.</p>
  </div>
</template>

<script>
export default {

}
</script>

<style>
p {
    width: 80vw;
    max-width: 800px;
    align-self: center;
}
.about-page {
    display: flex;
    flex-direction: column;
    align-content: center;
}
</style>